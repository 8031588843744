


































import { TranslateResult } from "vue-i18n";
import { Component, Vue } from "vue-property-decorator";
import { identifyUser } from "@/core/plugins/TrackingPlugin";
@Component
export default class PasswordForm extends Vue {
  password = "";
  errorMsg: string | TranslateResult = "";
  loading = false;

  async nextStep(): Promise<void> {
    this.loading = true;
    try {
      await this.$store.dispatch("register/setPassword", this.password);
      await this.$store.dispatch("register/createUser");
      // LOGIN
      identifyUser(this.$store.state.register.currentUser.id);
      this.$track({
        label: "bsignup_inputpassword_postregister",
      });
      this.$router.push({ name: "b-continue-to-app" });
      this.$store.dispatch("register/sendDownloadSMS");
    } catch ({ message }) {
      console.error(message);
      this.$track({ label: "bsignup_inputpassword_error_invalidpassword " });
    }
    this.loading = false;
  }

  mounted(): void {
    if (this.$store.state.register.password) {
      this.password = this.$store.state.register.password;
    }
  }
}
